@media print {
  /* Hides html elements that we want to hide when printing */
  .no-print {
    display: none !important;
  }

  /* Makes the print content more readable */
  .print-content {
    color: #2e2e2e !important;
    background-color: #ffffff !important;
    font-size: 12pt !important;
  }
}
