@media print {
  .no-print {
    display: none !important;
  }

  .print-content {
    color: #2e2e2e !important;
    background-color: #fff !important;
    font-size: 12pt !important;
  }
}

/*# sourceMappingURL=index.85b03db9.css.map */
